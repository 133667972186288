<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/info">Info</router-link>
  </nav> -->
  <router-view/>
</template>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.features-blue {
    color: #fff;
    background: linear-gradient(to right,#128036,#76DB66);
    background-color: #128036;
    padding-bottom: 30px
}

.features-black {
    color: #fff;
    background: linear-gradient(to right,#000000,#686968);
    background-color: #ffffff;
    padding-bottom: 30px
}

.features-grey {
    color: #fff;
    background: linear-gradient(to right,#303030,#303030);
    background-color: #ffffff;
}

.features-blue p {
    color: rgba(255, 255, 255, .6)
}

.features-blue h2 {
    font-weight: 700;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit
}

@media (max-width:767px) {
    .features-blue {
        padding-bottom: 10px
    }

    .features-blue h2 {
        margin-bottom: 25px;
        padding-top: 25px;
        font-size: 24px
    }
}

.features-blue .intro {
    font-size: 16px;
    max-width: 600px;
    margin: 0 auto 60px
}

.features-blue .item {
    min-height: 100px;
    padding-left: 80px;
    margin-bottom: 40px
}

@media (max-width:767px) {
    .features-blue .intro {
        margin-bottom: 40px
    }

    .features-blue .item {
        min-height: 0
    }
}

.features-blue .item .name {
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 20px;
    color: inherit
}

.features-blue .item .description {
    font-size: 15px;
    margin-bottom: 0
}

.features-blue .item .icon {
    font-size: 40px;
    color: white;
    float: left;
}

.footer-basic {
    padding: 40px 0;
    background-color: #fff;
    color: #4b4c4d
}

.footer-basic ul {
    padding: 0;
    list-style: none;
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 0
}

.footer-basic li {
    padding: 0 10px
}

.footer-basic ul a {
    color: inherit;
    text-decoration: none;
    opacity: .8
}

.footer-basic ul a:hover {
    opacity: 1
}

.footer-basic .social {
    text-align: center;
    padding-bottom: 25px
}

.footer-basic .social>a {
    font-size: 24px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #ccc;
    margin: 0 8px;
    color: inherit;
    opacity: .75
}

.footer-basic .social>a:hover {
    opacity: .9
}

.footer-basic .copyright {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #aaa;
    margin-bottom: 0
}

.header-dark {
    background-size: cover;
    padding-bottom: 80px
}

@media (min-width:768px) {
    .header-dark {
        padding-bottom: 120px
    }
}

.header-dark .navbar {
    background: 0 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    border: none
}

@media (min-width:768px) {
    .header-dark .navbar {
        padding-top: .75rem;
        padding-bottom: .75rem
    }
}

.header-dark .navbar .navbar-brand {
    font-weight: 700;
    color: inherit
}

.header-dark .navbar .navbar-brand:hover {
    color: #f0f0f0
}

.header-dark .navbar .navbar-collapse span {
    margin-top: 5px
}

.header-dark .navbar .navbar-collapse span .login {
    color: #d9d9d9;
    margin-right: .5rem;
    text-decoration: none
}

.header-dark .navbar .navbar-collapse span .login:hover {
    color: #fff
}

.header-dark .navbar .navbar-toggler {
    border-color: #747474;
    color: #eee
}

.header-dark .navbar .navbar-toggler:hover, .header-dark .navbar-toggler:focus {
    background: 0 0
}

.header-dark .navbar .form-inline, .header-dark .navbar .navbar-collapse {
    border-color: #636363
}

@media (min-width:992px) {
    .header-dark .navbar.navbar .navbar-nav .nav-link {
        padding-left: 1.2rem;
        padding-right: 1.2rem
    }
}

.header-dark .navbar.navbar-dark .navbar-nav .nav-link {
    color: #d9d9d9
}

.header-dark .navbar.navbar-dark .navbar-nav .nav-link:focus, .header-dark .navbar.navbar-dark .navbar-nav .nav-link:hover {
    color: #fcfeff !important;
    background-color: transparent
}

.header-dark .navbar .navbar-nav>li>.dropdown-menu {
    margin-top: -5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 2px
}

.header-dark .navbar .dropdown-menu .dropdown-item, .header-dark .navbar .dropdown-menu .dropdown-item:focus {
    line-height: 2;
    font-size: 14px;
    color: #37434d
}

.header-dark .navbar .dropdown-menu .drodown-item:hover, .header-dark .navbar .dropdown-menu .dropdown-item:focus {
    background: #ebeff1
}

.header-dark .navbar .action-button, .header-dark .navbar .action-button:active {
    background: #208f8f;
    border-radius: 20px;
    font-size: inherit;
    color: #fff;
    box-shadow: none;
    border: none;
    text-shadow: none;
    padding: .5rem .8rem;
    transition: background-color .25s
}

.header-dark .navbar .action-button:hover {
    background: #269d9d
}

.header-dark .navbar .form-inline label {
    color: #ccc
}

.header-dark .navbar .form-inline .search-field {
    display: inline-block;
    width: 80%;
    background: 0 0;
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    box-shadow: none;
    color: inherit;
    transition: border-bottom-color .3s
}

.header-dark .navbar .form-inline .search-field:focus {
    border-bottom: 1px solid #ccc
}

.header-dark .hero {
    margin-top: 60px
}

.header-dark .hero h1 {
    color: #fff;
    font-family: Bitter, serif;
    font-size: 40px;
    margin-top: 20px;
    margin-bottom: 80px
}

@media (min-width:768px) {
    .header-dark .hero {
        margin-top: 20px
    }

    .header-dark .hero h1 {
        margin-bottom: 50px;
        line-height: 1.5
    }
}

.header-dark .hero .embed-responsive iframe {
    background-color: #666
}

.navigation-clean {
    background: #fff;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #333;
    border-radius: 0;
    box-shadow: none;
    border: none;
    margin-bottom: 0
}

@media (min-width:768px) {
    .navigation-clean {
        padding-top: 1rem;
        padding-bottom: 1rem
    }
}

.navigation-clean .navbar-brand {
    font-weight: 700;
    color: inherit
}

.navigation-clean .navbar-brand:hover {
    color: #222
}

.navigation-clean .navbar-toggler {
    border-color: #ddd;
    color: #888
}

.navigation-clean .navbar-toggler:focus, .navigation-clean .navbar-toggler:hover {
    background: 0 0
}

.navigation-clean .form-inline, .navigation-clean .navbar-collapse {
    border-top-color: #ddd
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active, .navigation-clean.navbar-light .navbar-nav .nav-link.active:focus, .navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
    color: #8f8f8f;
    box-shadow: none;
    background: 0 0;
    pointer-events: none
}

.navigation-clean.navbar .navbar-nav .nav-link {
    padding-left: 18px;
    padding-right: 18px
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
    color: #465765
}

.navigation-clean.navbar-light .navbar-nav .nav-link:focus, .navigation-clean.navbar-light .navbar-nav .nav-link:hover {
    color: #37434d !important;
    background-color: transparent
}

.navigation-clean .navbar-nav>li>.dropdown-menu {
    margin-top: -5px;
    box-shadow: none;
    background-color: #fff;
    border-radius: 2px
}

.navigation-clean .dropdown-menu .dropdown-item, .navigation-clean .dropdown-menu .dropdown-item:focus {
    line-height: 2;
    color: #37434d
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
    background: #eee;
    color: inherit
}

.newsletter-subscribe {
    color: #313437;
    background-color: #fff;
    padding: 50px 0
}

.newsletter-subscribe p {
    color: #7d8285;
    line-height: 1.5
}

.newsletter-subscribe h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 25px;
    line-height: 1.5;
    padding-top: 0;
    margin-top: 0;
    color: inherit
}

.newsletter-subscribe .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto 25px
}

.newsletter-subscribe .intro p {
    margin-bottom: 35px
}

.newsletter-subscribe form {
    justify-content: center
}

.newsletter-subscribe form .form-control {
    background: #eff1f4;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    outline: 0;
    color: inherit;
    text-indent: 9px;
    height: 45px;
    margin-right: 10px;
    min-width: 250px
}

.newsletter-subscribe form .btn {
    padding: 16px 32px;
    border: none;
    background: 0 0;
    box-shadow: none;
    text-shadow: none;
    opacity: .9;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 1
}

.newsletter-subscribe form .btn:hover {
    opacity: 1
}

.newsletter-subscribe form .btn:active {
    transform: translateY(1px)
}

.newsletter-subscribe form .btn-primary {
    background-color: #128036 !important;
    color: #fff;
    outline: 0 !important
}

.testimonials-clean {
    color: #313437;
    background-color: #eef4f7
}

.testimonials-clean p {
    color: #7d8285
}

.testimonials-clean h2 {
    font-weight: 700;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit
}

@media (max-width:767px) {
    .testimonials-clean h2 {
        margin-bottom: 25px;
        padding-top: 25px;
        font-size: 24px
    }
}

.testimonials-clean .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto
}

.testimonials-clean .intro p {
    margin-bottom: 0
}

.testimonials-clean .people {
    padding: 50px 0 20px
}

.testimonials-clean .item {
    margin-bottom: 32px
}

@media (min-width:768px) {
    .testimonials-clean .item {
        height: 220px
    }
}

.testimonials-clean .item .box {
    padding: 30px;
    background-color: #fff;
    position: relative
}

.testimonials-clean .item .box:after {
    content: '';
    position: absolute;
    left: 30px;
    bottom: -24px;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-width: 12px 15px;
    border-top-color: #fff
}

.testimonials-clean .item .author {
    margin-top: 28px;
    padding-left: 25px
}

.testimonials-clean .item .name {
    font-weight: 700;
    margin-bottom: 2px;
    color: inherit
}

.testimonials-clean .item .title {
    font-size: 13px;
    color: #9da9ae
}

.testimonials-clean .item .description {
    font-size: 15px;
    margin-bottom: 0
}

.testimonials-clean .item img {
    max-width: 40px;
    float: left;
    margin-right: 12px;
    margin-top: -5px
}
</style>
